html,
body {
  height: 100%;
}

#navigator .navbar-nav .nav-item {
  font-size: 0.8rem;
}

#navigator .navbar-nav .nav-link {
  padding-left: 0.5rem;
  padding-right: 0rem;
}

/* ログイン */

#form-signin-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

#form-signin-container .form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

#form-signin-container .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

#form-signin-container .form-label-group > input,
#form-signin-container .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

#form-signin-container .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

#form-signin-container .form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input:-ms-input-placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input::-ms-input-placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input::-moz-placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input::placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

#form-signin-container .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* 注文テーブル */

#waiting-orders,
#cooking-orders,
#cooked-orders {
  padding: 0;
}

.scrollable-orders {
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

.h-80 {
  height: 80% !important;
}

.fadeOut {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

tr.component-line-top {
  border-top: 1px solid black;
}

tr.component-line-bottom {
  border-bottom: 1px solid black;
}

.hidden-order {
  visibility: hidden;
}

.reset-bootstrap-for-datasheet * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.reset-bootstrap-for-datasheet *:before,
.reset-bootstrap-for-datasheet *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.reset-bootstrap-for-datasheet {
  height: initial;
  line-height: initial;
}

#current-slots-floating-card {
  background-color: #f2f2f2;
  border-radius: 0;
}

.navigator-order {
  border-radius: 0;
}

.navigator-order .navigator-order-icon-text {
  margin-right: 1rem;
}

.react-confirm-alert-body > h1 {
  font-size: 1.2rem;
}

.slot-pattern {
  height: 100%;
}

.slot-pattern thead th {
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
}

.slot-pattern .shopname {
  height: 100px;
   overflow: auto;
}

.slot-pattern th {
  border: 1px solid black;
  background-color: white;
}

.slot-pattern th.time {
  vertical-align: center;
  text-align: center;
  height: 30px;
  width: 60px;
}

.slot-pattern th.line {
  width: 250px;
  vertical-align: center;
  text-align: center;
}

.slot-pattern th.slot {
  width: 200px;
  vertical-align: top;
  text-align: center;
}

.slot-pattern .alert {
  display: inline-block;
}

.slot-pattern td {
  border: 1px solid black;
  min-height: 80px;
}

.slot-pattern td div {
  height: 100%;
  vertical-align: top;
  overflow: hidden;
}

.assign-patterns thead th {
  border: 1px solid black;
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
}

.assign-patterns th {
  border: 1px solid black;
  background-color: white;
}

.assign-patterns th.time {
  vertical-align: center;
  text-align: center;
  width: 60px;
}

.assign-patterns th.day {
  width: 150px;
  vertical-align: bottom;
  text-align: center;
}

.assign-patterns th.day span.holiday {
  color: red;
}

.assign-patterns th.pattern {
  width: 100px;
  vertical-align: bottom;
  text-align: center;
}

.assign-patterns .alert {
  display: inline-block;
}

.assign-patterns td {
  border: 1px solid black;
}

.assign-patterns td div {
  height: 100%;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
}

.assign-patterns td div label {
  display: block;
  cursor: pointer;
  margin: 0;
}

.assign-patterns td div label input {
  cursor: pointer;
}

.topping-list thead th {
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
}

.topping-list th {
  background-color: white;
}

/* --------------------------------------------------
   調理カード
-------------------------------------------------- */
.cooking-order-card {
  position: relative;
  margin: 4px auto;
  max-width: 1024px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}

.cooking-order-card-alert {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.cooking-order-card-alert .alert {
  width: 480px;
}

/* 調理カードヘッダー */
.cooking-order-card .cooking-order-card-header {
  padding: 4px 8px 4px 8px;
}

.cooking-order-card .cooking-order-card-header .cooking-order-card-number {
  margin-right: 16px;
  font-size: 30px;
  font-weight: bold;
}

.cooking-order-card .cooking-order-card-header .cooking-order-card-estimated-at {
  font-size: 24px;
}

.cooking-order-card .cooking-order-card-header .cooking-order-card-estimated-at span {
  font-size: 16px;
}

.cooking-order-card .cooking-order-card-header .badge {
  font-size: 18px;
  margin: 0 0 0 8px;
}

.cooking-order-card .cooking-order-card-header .badge.cooking-status {
  font-size: 12px;
  margin: 0 8px 0 0;
}

.cooking-order-card .cooking-order-card-header .cooking-order-card-customer {
  display: inline-block;
}

/* 調理カードボディ */
.cooking-order-card .cooking-order-card-body {
  padding: 0px 2px 8px 2px;
}

/* 調理カードのエレメント */
.cooking-order-card-border-blue {
  border-color: rgba(13, 110, 253, 1) !important;
}
.cooking-order-card-border-indigo {
  border-color: rgba(102, 16, 242, 1) !important;
}
.cooking-order-card-border-purple {
  border-color: rgba(111, 66, 193, 1) !important;
}
.cooking-order-card-border-pink {
  border-color: rgba(214, 51, 132, 1) !important;
}
.cooking-order-card-border-red {
  border-color: rgba(220, 53, 69, 1) !important;
}
.cooking-order-card-border-orange {
  border-color: rgba(253, 126, 20, 1) !important;
}
.cooking-order-card-border-yellow {
  border-color: rgba(255, 193, 7, 1) !important;
}
.cooking-order-card-border-green {
  border-color: rgba(25, 135, 84, 1) !important;
}
.cooking-order-card-border-teal {
  border-color: rgba(32, 201, 151, 1) !important;
}
.cooking-order-card-border-cyan {
  border-color: rgba(13, 202, 240, 1) !important;
}
.cooking-order-card-border-emerald {
  border-color: rgba(23, 162, 184, 1) !important;
}
.cooking-order-card-border-brown {
  border-color: rgba(184, 94, 22, 1) !important;
}
.cooking-order-card-border-gray {
  border-color: rgba(108, 117, 125, 1) !important;
}
.cooking-order-card-border-white {
  border-color: rgba(0, 0, 0, 1) !important;
}

.cooking-order-card-bg-blue {
  background-color: rgba(13, 110, 253, 0.2) !important;
}
.cooking-order-card-bg-indigo {
  background-color: rgba(102, 16, 242, 0.2) !important;
}
.cooking-order-card-bg-purple {
  background-color: rgba(111, 66, 193, 0.2) !important;
}
.cooking-order-card-bg-pink {
  background-color: rgba(214, 51, 132, 0.2) !important;
}
.cooking-order-card-bg-red {
  background-color: rgba(220, 53, 69, 0.2) !important;
}
.cooking-order-card-bg-orange {
  background-color: rgba(253, 126, 20, 0.2) !important;
}
.cooking-order-card-bg-yellow {
  background-color: rgba(255, 193, 7, 0.2) !important;
}
.cooking-order-card-bg-green {
  background-color: rgba(25, 135, 84, 0.2) !important;
}
.cooking-order-card-bg-teal {
  background-color: rgba(32, 201, 151, 0.2) !important;
}
.cooking-order-card-bg-cyan {
  background-color: rgba(13, 202, 240, 0.2) !important;
}
.cooking-order-card-bg-emerald {
  background-color: rgba(23, 162, 184, 0.2) !important;
}
.cooking-order-card-bg-brown {
  background-color: rgba(184, 94, 22, 0.2) !important;
}
.cooking-order-card-bg-gray {
  background-color: rgba(108, 117, 125, 0.2) !important;
}
.cooking-order-card-bg-white {
  background-color: rgba(255, 255, 255, 1) !important;
}

.cooking-order-card .cooking-order-card-name {
  margin: 0 8px 8px 8px;
  font-size: 14px;
}

.cooking-order-card .cooking-order-card-element-group-container {
  min-height: 363px;
}

.cooking-order-card .cooking-order-card-element-group-container .cooking-order-card-element-group {
  margin: 0px 1px 10px 1px;
  padding-top: 2px;
  border-top: 4px solid transparent;
}

.cooking-order-card .cooking-order-card-element-group-container .cooking-order-card-element-group.disabled {
  border-top: 4px solid transparent !important;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper {
  position: relative;
  display: inline-block;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-visualized-spacer {
  position: relative;
  display: inline-block;
  min-height: 112px;
  width: 100%;
  outline: none;
  border: 2px dashed #ccc;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element-checkmark-wrapper {
  position: absolute;
  left: 90%;
  top: 0;
  transform: translate(-50%, -30%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 999;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element-checkmark-wrapper
  .cooking-order-card-element-checkmark-circle {
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  padding: 0;
  border-radius: 50%;
  background: #fff;
  outline: none;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element-checkmark-wrapper
  .cooking-order-card-element-checkmark-circle.unchecked {
  width: 100%;
  height: 100%;
  border-color: #f00 !important;
  border-radius: 50%;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element-checkmark-wrapper
  .cooking-order-card-element-checkmark-circle
  .cooking-order-card-element-checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #000;
  white-space: nowrap;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element-checkmark-wrapper
  .cooking-order-card-element-checkmark-circle.checked
  .cooking-order-card-element-checkmark {
  color: #000;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element-checkmark-wrapper
  .cooking-order-card-element-checkmark-circle.unchecked
  .cooking-order-card-element-checkmark {
  color: #f00;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element {
  vertical-align: top;
  display: inline-block;
  width: 54px;
  min-height: 105px;
  margin: 0px 1px;
  padding: 8px 0px 0px 0px;
  border: 2px solid transparent;
  border-radius: 4px;
  outline: none;
  color: #000;
}


.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element-wrapper
  .cooking-order-card-element.unchecked {
  border-color: #f00 !important;
  color: #f00 !important;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element.disabled {
  opacity: 0.16;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element.disabled
  * {
  opacity: 0;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element
  .cooking-order-card-element-name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element
  .cooking-order-card-element-count {
  font-size: 18px;
  line-height: 32px;
  height: 32px;
  text-align: center;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element
  .cooking-order-card-element-count.emphasize {
  font-size: 28px;
  font-weight: bold;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element
  .cooking-order-card-element-weight {
  font-size: 12px;
  line-height: 1.2;
  font-weight: normal;
  text-align: center;
}

.cooking-order-card
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element
  .cooking-order-card-element-price {
  font-size: 12px;
  line-height: 1.2;
  font-weight: normal;
  text-align: center;
}

.cooking-order-card .cooking-order-card-timer {
  padding: 0 4px;
  font-family: monospace;
}

/* 調理カードフッター */
.cooking-order-card .cooking-order-card-footer {
  display: flex;
  margin-bottom: 16px;
  padding: 0 4px;
  height: 120px;
}

.cooking-order-card .cooking-order-card-comment-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  padding: 8px;
  border-radius: 8px;
  background: #e7e8ea;
  color: #6c757d;
  overflow: hidden;
}

.cooking-order-card .cooking-order-card-comment-box.disabled {
  opacity: 0.16;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-comment-box-title {
  font-size: 12px;
  margin-bottom: 8px;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-comment-box-body {
  overflow: auto;
  font-size: 16px;
  font-weight: bold;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-cs-request {
  overflow: auto;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-cs-request ul {
  margin: 0;
  padding: 0 0 0 1em;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-cs-request ul li {
  font-size: 12px;
  font-weight: bold;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-feedbacks {
  overflow: auto;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-feedback {
  margin-bottom: 8px;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-feedback-title {
  font-size: 12px;
}

.cooking-order-card .cooking-order-card-comment-box .cooking-order-card-feedback-body {
  font-size: 12px;
  font-weight: bold;
}

.cooking-order-card .cooking-order-card-primary-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cooking-order-card .cooking-order-card-primary-button {
  flex: 2;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}

.cooking-order-card .cooking-order-card-primary-button.btn-danger {
  flex: 1;
}

.cooking-order-card .cooking-order-card-primary-button:last-child {
  margin-bottom: 0;
}

/* 盛り付けレイアウト編集 */
.edit-kitchen-layout {
  max-width: 100%;
}

.edit-kitchen-layout button:focus {
  outline: none;
}

.edit-kitchen-layout form {
  padding: 16px;
}

.edit-kitchen-layout
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  .cooking-order-card-element {
  position: relative;
  height: 97px;
  text-align: center;
}

.edit-kitchen-layout .cooking-order-card-element-control {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.edit-kitchen-layout .cooking-order-card-element-control strong {
  display: block;
  margin-bottom: 8px;
}

.edit-kitchen-layout .cooking-order-card-element button {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  text-align: center;
}

.edit-kitchen-layout .cooking-order-card-sortable {
  display: flex;
}

.edit-kitchen-layout .cooking-order-card-sortable button {
  flex: 1;
}

.edit-kitchen-layout
  .cooking-order-card-element-group-container
  .cooking-order-card-element-group
  button.cooking-order-card-element {
  border: 1px dotted #ccc !important;
  background: #f5f5f5 !important;
  font-size: 12px;
}

.edit-kitchen-layout .cooking-order-card-group-control button {
  margin-top: 4px;
  display: block;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.edit-kitchen-layout .cooking-order-card-element-group-container button.cooking-order-card-element-group {
  position: relative;
  border: 1px dotted #ccc !important;
  background: #f5f5f5 !important;
  border-radius: 8px;
  width: 128px;
  height: 103px;
  font-size: 12px;
}

/* --------------------------------------------------
   ツールチップ
-------------------------------------------------- */
.order-tooltip {
  position: relative;
  cursor: pointer;
}

.order-tooltip-content {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 100%);
  bottom: -7px;
  display: inline-block;
  padding: 4px 8px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  transition: 0.3s ease-in;
}

.order-tooltip[data-order-tooltip-position='left'] .order-tooltip-content {
  left: 0%;
  right: auto;
  transform: translate(0%, 100%);
}

.order-tooltip[data-order-tooltip-position='right'] .order-tooltip-content {
  left: auto;
  right: 0%;
  transform: translate(0%, 100%);
}

.order-tooltip-content:before {
  content: '';
  position: absolute;
  top: -14px;
  left: 50%;
  margin-left: -7px;
  border: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.8);
}

.order-tooltip[data-order-tooltip-position='left'] .order-tooltip-content:before {
  left: 10%;
  right: auto;
}

.order-tooltip[data-order-tooltip-position='right'] .order-tooltip-content:before {
  left: auto;
  right: 10%;
}

.order-tooltip:hover .order-tooltip-content,
.order-tooltip[data-order-tooltip-active='true'] .order-tooltip-content {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.order-customer-tooltip .order-tooltip-content {
  padding: 16px;
}

.order-tooltip table {
  font-size: 14px;
}

.order-tooltip table th,
.order-tooltip table td {
  padding: 2px 8px;
}

.order-tooltip table td {
  padding: 2px 8px;
  font-weight: normal;
}

.order-tooltip table a {
  color: #fff;
  text-decoration: underline;
}

.order-tooltip table a:hover {
  text-decoration: none;
}

/* カスタマー情報のツールチップ */
.order-customer-tooltip .nickname {
  color: #007bff;
}

/* https://github.com/reactjs/react-modal */
.ReactModal__Overlay {
  z-index: 9999;
}

/* https://github.com/GA-MO/react-confirm-alert */
.react-confirm-alert-overlay {
  z-index: 99999 !important;
}

.shelf {
  width: 75px;
  height: 150px;
  border: 1px solid black;
  padding: 1px;
  margin: 3px;
  border-radius: 0.25rem;
}

.shelf-item {
  height: 120px;
}
